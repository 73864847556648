import React from 'react';

import AlertCircleFill from './icons/alert-circle-fill';
import SealCheck from './icons/seal-check';
import Plus from './icons/plus';
import Receipt from './icons/receipt';
import Question from './icons/question';
import Dollar from './icons/dollar';
import Upload from './icons/upload';
import Calendar from './icons/calendar';
import File from './icons/file';
import Trash from './icons/trash';
import TrashFill from './icons/trash-fill';
import XCircle from './icons/x-circle';
import CheckCircle from './icons/check-circle';
import ClockCountdown from './icons/clock-countdown';
import DollarCircle from './icons/dollar-circle';
import DollarCircleFill from './icons/dollar-circle-fill';
import Broom from './icons/broom';
import WarningCircle from './icons/warning-circle';
import Check from './icons/check';
import Spinner from './icons/spinner';
import X from './icons/x';
import Star from './icons/star';
import Clock from './icons/clock';
import Ribbon from './icons/ribbon';
import CarDetailingActive from './icons/car-detailing-active';
import CarWashActive from './icons/car-wash-active';
import CourierActive from './icons/courier-active';
import DogActive from './icons/dog-active';
import DryCleaningActive from './icons/dry-cleaning-active';
import ErrandsActive from './icons/errands-active';
import FurnitureAssemblyActive from './icons/furniture-assembly-active';
import JunkRemovalActive from './icons/junk-removal-active';
import MealPrepActive from './icons/meap-prep-active';
import MowingActive from './icons/mowing-active';
import OrganizingActive from './icons/organizing-active';
import PaintbrushActive from './icons/painting-active';
import PetSittingActive from './icons/pet-sitting-active';
import StrollerActive from './icons/stroller-active';
import WeedingActive from './icons/weeding-active';
import Hourglass from './icons/hourglass';
import House from './icons/house';
import WarningCircleFill from './icons/warning-circle-fill';
import LightningSlash from './icons/lightning-slash';
import Lightning from './icons/lightning';

import type { SingleIconProps } from './types';

export const icons = {
  'alert-circle-fill': AlertCircleFill,
  'car-detailing-active': CarDetailingActive,
  'car-wash-active': CarWashActive,
  'check-circle': CheckCircle,
  'clock-countdown': ClockCountdown,
  'courier-active': CourierActive,
  'dog-active': DogActive,
  'dollar-circle-fill': DollarCircleFill,
  'dollar-circle': DollarCircle,
  'dry-cleaning-active': DryCleaningActive,
  'errands-active': ErrandsActive,
  'furniture-assembly-active': FurnitureAssemblyActive,
  'junk-removal-active': JunkRemovalActive,
  lightning: Lightning,
  'lightning-slash': LightningSlash,
  'meal-prep-active': MealPrepActive,
  'mowing-active': MowingActive,
  'organizing-active': OrganizingActive,
  'paintbrush-active': PaintbrushActive,
  'pet-sitting-active': PetSittingActive,
  'seal-check': SealCheck,
  'stroller-active': StrollerActive,
  'warning-circle': WarningCircle,
  'weeding-active': WeedingActive,
  'x-circle': XCircle,
  'warning-circle-fill': WarningCircleFill,
  broom: Broom,
  calendar: Calendar,
  check: Check,
  dollar: Dollar,
  file: File,
  hourglass: Hourglass,
  plus: Plus,
  question: Question,
  receipt: Receipt,
  spinner: Spinner,
  x: X,
  star: Star,
  clock: Clock,
  ribbon: Ribbon,
  trash: Trash,
  'trash-fill': TrashFill,
  upload: Upload,
  house: House,
} as const;

export type IconName = keyof typeof icons;

export default function Icon({
  name,
  size,
  color,
  className,
  accessibilityLabel,
}: SingleIconProps & { name: IconName }) {
  // leaving this .toLowerCase although type checking should prevent a misuse
  // of capitals in the icon name, just to make sure nothing weird ever happens
  const IconComponent = icons[name.toLowerCase() as IconName];
  if (!IconComponent) {
    if (process.env.NODE_ENV !== 'production')
      // eslint-disable-next-line no-console
      console.warn(
        `Icon with name \`${name}\` not found. Check the icon name ` +
          `and make sure it is imported in \`icons/index.tsx\` and added to ` +
          `the \`icons\` object in the file`,
      );
    return null;
  }
  return (
    <IconComponent
      size={size}
      color={color}
      // style={{ minHeight: size, minWidth: size }}
      className={className}
      accessibilityLabel={accessibilityLabel}
    />
  );
}
